*{
  margin: 0;
  padding: 0;
}
.content{
  background: linear-gradient(135deg,#850c62,#f80759);
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.location{
  line-height: 1.5rem;
}
.app-wrapper {
  background-color: #fff;
  min-width: 350px;
  min-height: 650px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
}
.title {
  color: #f80759;
  text-align: center;
  margin: 75px 0px 12px 0px;
}
.name,
.email,
.password {
  margin: 28px;
  color: #969292;
  font-size: 16px;
}
.password{
  margin-bottom: 40px;
}
.submit {
  display: flex;
text-transform: uppercase;
font-size:  20px;
border-radius: 25px;
color:#fff;
padding: 18px 68px;
background: blueviolet;
margin: auto;
outline: none;
border: none;
cursor: pointer;
transition: all 0.1s;
}
.signup-submit {
  display: flex;
text-transform: uppercase;
font-size:  20px;
border-radius: 25px;
color:cornflowerblue;
padding: 18px 68px;
background: white;
margin: auto;
outline: none;
border: none;
cursor: pointer;
transition: all 0.1s;
}
.input {
 height: 32px;
 font-size: 20px;
 outline: none;
 width: 100%;
 border-top-style: none;
 border-left-style: none;
 border-right-style: none;
 border-bottom-style: 2px solid black;
 margin-bottom: 10px;
}
.error{
  color: red;
}
button:active {
transform: translate(2px);
}
.form-success{
  display: flex;
  justify-content: center;
  margin-top: 250px;
  min-width: 370px;
  color: #f80759;

}
.nav {
display: flex;
justify-content: space-around;
align-items: center;
min-height: 10vh;
background: rgb(109, 116, 116);
color: white;
}

.nav-Link{
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
.button{
  display: flex;
  justify-content: flex-end;
}
.employee{
  width: 700px;
  height: auto;
  border: 1px solid black;
  margin: 10px;
  display: flex;
  padding: 10px;
}
.App {
  text-align: left;
}
body{
  overflow:hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61DAFB;
}
.bgImage{
  width: 100vw;
  background-position:center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
@font-face {
  font-family: "Faux sanskrit";
  src: url("SamarkanOblique-BXew.ttf");
}
.Sanskritfont {
  font-family: 'Faux sanskrit';
  text-align: left;
  margin: 55px 5px 5px 5px ;
  font-size: 15px;
}
.Sanskritfont1 {
  font-family: 'Faux sanskrit';
  text-align: left;
  margin: 10px 19px 5px 0px ;
  font-size: 15px;
}
.subtetxt span {
  font-size: 10px;
}
span{display:block; clear:both; margin-bottom:-40px;}
.Textcolor{
  color: #FAFAFA;
  font-size: 12px;
  word-wrap: break-word;
   text-align: justify;
}
.title{
    color:    yellow;
    font-size:  55px;
    font-style: inherit;
}
.subText1{
  display: inline;
}
.icon-bar {
  position: fixed;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}
.icon-bar a {
  display: block;
  text-align: center;
  padding: 12px;
  transition: all 0.3s ease;
  color: white;
  font-size: 14px;
}
.facebook {
  background: #3B5998;
  color: white;
}
.twitter {
  background: #55ACEE;
  color: white;
}
.gmail {
  background: #DD4B39;
  color: white;
}
.linkedin {
  background: #007BB5;
  color: white;
}
.Textcolor{
  color: #FAFAFA;
  font-size: 12px;
  word-wrap: break-word;
   text-align: justify;
}
.content{
  text-align: center;
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
}
.overlay{
  text-align: center ;
  height: 250%;
  width: 250px;
  font-family: 'Faux sanskrit';
  position:relative;
  top: -4px;
  display: inline;
  font-size:32px;
}
.ribbon {
 /* background-image:url(./Hamburger_menu.png); */
  width: 100%;
  height: auto;
  background-repeat:no-repeat;
  background-position:left top;
  margin-top: 30px;
}
.sideitems{
  text-align: left;
  height: 100%;
  margin-top: 4%;
  width: 140px;
  color: White;
  font-size: 11px;
  
}
@font-face {
  font-family: "Faux sanskrit";
  src: url("SamarkanOblique-BXew.ttf");
}
.bottomleft {
  text-align: center;
  height: 200%;
  width: 250px;
}
.padding-0{
  padding-right:0;
  padding-left:0;
}
@media (min-height: 1200px ) {
  .row {
      position: relative;

      
     
      
      
      
  }
  
  
  .bottom-align-text {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media (min-width: 768px) {
  .row.equal {
    display: flex;
    flex-wrap: wrap;
  }
}
/* css added by Siva 10/06/2021 */
.menu-bars{
  font-size:28px;
  color:#fff;
  display: inline;
  margin-right: 1rem;
}
.top-space{
  padding-top: 8px;
  position: static;
  width:21%;
  height:100vh;
  padding-left:20px;
}
.side-menu-option,.aboutustext{
  color:#fff;
  font-size:12px;
  
}
.list-none{
  list-style: none;
  margin-left:0!important;
  padding-left: 10px;
  margin-top:50px;
}
.list-none li{
  font-weight: bold;
  margin-bottom: 5px;
}
.list-none li i{
  float: right;
  margin-top: 2px;
  margin-right: 20px;
}
.terms-policy{
  position:fixed ;
  bottom: 10px;
  padding-left: 14px;
  font-size: 9px;
}
.cont{
  display: flex;
  /* overflow: scroll; */
  overflow: auto;
  
}
.page-content{
  width: 100%;
  
}
.card-collection{
  display:flex;
  position: fixed;
  right: 0;
  bottom: 0;
}
.card-body{
  max-height: 180px;
  height: 180px;;
}
.card-body i{
  position: absolute;
  bottom:15px;
  right: 15px;
}
.clear-fix{
  min-height:200px;
}
.btn{
  border:1px solid #ddd;
}
.height-fix-1{
  height:10%;
  overflow:auto;
  margin-bottom:10px;
}
.orange-background{
  background-color:#E65400;
}

.projects-page .card{
  min-width:500px;
}
@media only screen and (max-width: 992px ) {
  body {
    overflow: auto;
  }
  .clear-fix{
    min-height: 120px;
  }
}
@media only screen and (max-width: 1200px ) {
  body {
    overflow: auto;
  }
  .page-content{
    width:100%;
  }
  .cont{
    display: inline-block;
  }
  .card-collection{
    position: relative;
  }
  .top-space{
    height:fit-content;
    width: 100%;
  }
  .clear-fix{
    min-height: 40px;
  }

.terms-policy{
  position: relative;
  
}
}





@media (max-width: 768px ) {
  body {
    overflow: auto;
  }
  .page-content{
    width:98%
  }
  .cont{
    display: inline-block;
  }
  .card-collection{
    position: relative;
  }
  .top-space{
    height:fit-content;
    width: 100%;
  }
  .clear-fix{
    min-height: 40px;
  }
  .projects-page .card{
    min-width:350px;
  }

.terms-policy{
  position: relative !important;
  bottom: 0px !important;
}
}

@media only screen and (max-width: 480px ) {
  body {
    overflow: auto;
  }
  .page-content{
    width:98%
  }
  .cont{
    display: inline-block;
  }
  .card-collection{
    position: relative;
    padding:30px 15px;
    width: 85%;
  }
  .top-space{
    height:fit-content;
    width: 100%;
  }
  .clear-fix{
    min-height: 40px;
  }
  .terms-policy{
    position: relative;
    
  }
  .projects-page .card{
    min-width:270px;
  }
  
}
.projectCard{
  height: 250px;
  width: 250px;
  border:2px solid black;
  position: relative;
  align-content: center;
  display: flex;
}
.carousel .control-dots{
  margin-bottom: 4%;
}
.carousel.carousel-slider .control-arrow{
  display: none;
}
.carousel .carousel-status{
  display: none; 
}
/* Split the screen in half */
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the right side */
.right {
  right: 0;
  /* background-color: rgb(247, 247, 247) */
}

/* If you want the content centered horizontally and vertically */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.paragraph{
  text-align: left;
  color: white;
  font-size: 14px;
  padding: 2px;
  padding-right: 75px;
  justify-content: center;
}
.appdownload{
  text-align: center;
  color: white;
  font-size: 25px;
}

.cardContentstyle {
  text-align: justify;
  color: white;
  font-size: 12px;
  padding: 4px;
  height: 4.5rem;
}
.demoContainer{
  display: flex;
  /* overflow: scroll; */
  overflow: auto;
}
html,body{
  height: 100vh;
  background-image: url(./Pramanam_background.jpg); 
  background-position: center center;
  background-size:cover 
}
/* .globalCard{
  body: background-image none;
} */
.GlobalContainer {
  background: #f9f9f9;
  display: grid;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height:100vh; 
 
}
.centerAlign{
  display: flex ;

  justify-content: center;

}


.row-heading{
  text-align: center ;
  font-family: "Faux sanskrit";
  src: url("SamarkanOblique-BXew.ttf");
  color: yellow;
  font-size: 50px;
}
.subTex{
  display: inline;
  text-align: center ;
  
}
.subTex::-webkit-scrollbar {
  width: 0px;
  background: transparent;
 }
 
.taller {
  padding-top: 500px;
}
.box > span {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.data-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1000%;

}
/* @media only screen and (max-width: 840px ) {
  .large-screen{
    width: 100%;
    
    
  }

} */
.gm-style-pbc {
  transition:opacity ease-in-out;
  background-color:rgba(0,0,0,0.45);
  text-align:center
 }
 .gm-style-pbt {
  font-size:22px;
  color:white;
  font-family:Roboto,Arial,sans-serif;
  position:relative;
  margin:0;
  top:50%;
  -webkit-transform:translateY(-50%);
  -ms-transform:translateY(-50%);
  transform:translateY(-50%)
 }
 button.rec-dot{
  background-color: white;
  box-shadow: 0 0 1px 3px #E65400;
}

button.rec-arrow-left:hover, button.rec-arrow-right:hover, button.rec-dot:active, button.rec-dot:focus  {
  background-color: white !important;
  
}

.gs_hr{
  color: red;
  width: 100%
}


@media only screen and (max-width : 720px ) {
.gsrow{
  padding-top: 12rem;
  padding-left: 5rem;
}
.userControw{
  padding-top: 3rem;
  padding-left: 5rem;
}
.gs_h2{
  font-size: 3rem;
}
.userContCounter{
  font-size: 3rem;
  padding-left: 3rem
}
}

@media only screen and (min-width: 800px ) {
.gsrow{
  padding-left: 20rem;
  align-content: center
}
.userControw{
  padding-top: 3rem;
  padding-left: 0rem;
  align-content: center
}
.gs_h2{
  font-size: 5rem;
  color: #535353;

}
.userContCounter{
  font-size: 10rem;
  color: #535353;

}
.gs_h1{
  
  
  color: #535353;
  text-align: center;
}
.userContText{
  color: #535353;
  text-align: center;
 
}
.center {

  display: flex;

  justify-content: center;

  align-items: center;

  height: 150px;

  background: #999FF0;

}
}

